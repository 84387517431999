<template v-if="this.hasView === true">
  <CRow>
    <CCol md="6">
      <CCard class="update-profile-card">
        <CCardHeader>
          <CButton
            v-if="hasPass"
            color="info"
            class="mr-1"
            shape="pill"
            @click="goDirection('change-password')"
          >{{$t('PROFILE_PAGE_BUTTON_CHANGE_PASSWORD')}}</CButton>
          <CButton
            v-if="hasHistory"
            color="info"
            class="mr-1"
            shape="pill"
            @click="goDirection('login-history')"
          >{{$t("PROFILE_PAGE_BUTTON_LOGIN_HISTORY")}}</CButton>
        </CCardHeader>
        <CCardBody>
          <ValidationObserver ref="form">
            <ValidationProvider name="PROFILE_PAGE_FIELD_NAME_VALIDATE_NAME" rules="required" v-slot="{ errors }">
              <CInput :label="$t('PROFILE_PAGE_LABEL_NAME')" horizontal v-model="fullName" class="form-group--custom" />
              <CRow>
                <CCol :sm="{offset:'3'}"></CCol>
                <CCol :sm="9">
                  <span class="error-msg mt-1" v-show="errors[0]">{{ errors[0] }}</span>
                </CCol>
              </CRow>
            </ValidationProvider>
          </ValidationObserver>
          <!-- <CInput
            label="Email"
            :value="email"
            type="email"
            horizontal
            autocomplete="email"
            :readonly="true"
          />-->
          <div class="form-group--custom form-group form-row">
            <label class="col-sm-3">{{$t("PROFILE_PAGE_LABEL_EMAIL")}}</label>
            <h6 class="col-sm-9">
              <strong>{{ email }}</strong>
            </h6>
          </div>
          <CRow v-if="has2FA">
            <CCol md="3">
              <h6 class="mt-1">{{$t("PROFILE_PAGE_LABEL_2FA")}}</h6>
            </CCol>
            <CCol md="9">
              <CSwitch
                color="success"
                @change.native="check(checked2fa)"
                :checked="checked2fa"
                variant="3d"
                v-bind="labelIcon"
              />
            </CCol>
          </CRow>
          <div class="form-actions" v-if="hasUpdate">
            <CButton @click="updateProfile()" class="btn--save">{{$t("PROFILE_PAGE_BUTTON_SAVE")}}</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715',
      },
      fullName: '',
      hasView: false,
      hasPass: false,
      hasHistory: false,
      hasUpdate: false,
      has2FA: false,
    };
  },
  computed: {
    ...mapState({
      checked2fa: state => state.auth.twofaEnableFlag,
      email: state => state.auth.userEmail,
      name: state => state.auth.userName,
      userPermission: state => state.auth.userPermissions,
    }),
  },
  mounted() {
    this.fullName = this.name;

    this.checkPermission();
  },
  methods: {
    ...mapActions(['updateProfileName']),
    goDirection(value) {
      this.$router.push(`profile/${value}`);
    },
    check(value) {
      this.$router.push('profile/setting-2fa');
    },
    async updateProfile() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        const data = {
          name: this.fullName,
        };
        this.updateProfileName(data);
      });
    },
    checkPermission() {
      if (this.userPermission.length > 0) {
        this.userPermission.forEach(items => {
          if (items.name === 'VIEW_ACCOUNT') {
            this.hasView = true;
          }

          if (items.name === 'CHANGE_PASSWORD_ACCOUNT') {
            this.hasPass = true;
          }

          if (items.name === 'VIEW_LOGIN_HISTORY_ACCOUNT') {
            this.hasHistory = true;
          }

          if (items.name === 'UPDATE_PROFILE_ACCOUNT') {
            this.hasUpdate = true;
          }

          if (items.name === 'UPDATE_2FA_ACCOUNT') {
            this.has2FA = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-profile-card {
  .error-msg {
    color: red;
  }
  .form-actions {
    text-align: right;
    padding-right: 0;
    .btn--save {
      min-width: 146px;
      border-radius: 4px;
      background-color: #3b4b7e;
      outline: none !important;
      color: #fff;
    }
  }
  .form-row {
    margin-top: 1em;
    margin-bottom: 5px;
  }
}
</style>
